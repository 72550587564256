import React from 'react';
import {graphql} from 'gatsby';
import Img from 'gatsby-image';
import Helmet from 'react-helmet';
import {
  Banner,
  Bio,
  Container,
  Footer,
  Grid,
  Heading,
  Page,
  Section,
  Show,
  Subheading,
  TopBar,
  VideoGallery
} from '../components';

import './styles.css'
import styles from './index.module.css';

export default ({data}) => {
  const videos = [
    {
      id: 'xyqVhCJX4cA',
      thumbnail: data.comfreyOfficialImage.childImageSharp.fluid,
      title: 'Comfrey and Calendula (Official Video)',
    },
    {
      id: 'd0IE4A2gnYk',
      thumbnail: data.boysClubImage.childImageSharp.fluid,
      title: 'Boys Club',
    },
    {
      id: 'IWn5Ve8HkAk',
      thumbnail: data.palominoImage.childImageSharp.fluid,
      title: 'Palomino Princess',
    },
    {
      id: 'f4DsTL3HSqI',
      thumbnail: data.comfreyImage.childImageSharp.fluid,
      title: 'Comfrey and Calendula (Acoustic)',
    },
  ];

  const showsMarkup = data.allContentfulShow.edges.length === 0
    ? <p>No upcoming shows at the moment.</p>
    : data.allContentfulShow.edges.map((show) => {
    return (
      <Show
        key={show.node.url}
        title={show.node.title}
        url={show.node.url}
        location={show.node.location}
        date={show.node.date}
      />
    );
  });
  
  return (
    <Page>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Fliss</title>
        <link rel="canonical" href="https://flissmusic.com" />
        <meta name="description" content="Felicity DeCarle, Musician" />
        <meta name="keywords" content="music,fliss,sparklesaurus" />
        <html lang="en" />
      </Helmet>
      <TopBar />
      <Container>
        <Heading>Fliss</Heading>
      </Container>
      <Banner
        image={data.bannerImage.childImageSharp.fluid}
      />
      <Section>
        <Container>
          <div className={styles.Callout}>
            <div className={styles.Content}>
              <Subheading>Comfrey and Calendula</Subheading>
              <p>Debut single OUT NOW</p>
              <div className={styles.Actions}>
                <a className={styles.Action} href="https://distrokid.com/hyperfollow/fliss1/comfrey-and-calendula">Listen</a>
                <a className={styles.Action} href="https://www.youtube.com/watch?v=xyqVhCJX4cA2">Watch Video</a>
              </div>
            </div>
            <Img className={styles.Image} fixed={data.albumImage.childImageSharp.fixed} alt="Album cover for Comfrey and Calendula" />
          </div>
        </Container>
      </Section>
      <Section>
        <Bio image={data.portraitImage.childImageSharp.fixed} />
      </Section>
      <Section>
        <Container>
          <Subheading>Videos</Subheading>
        </Container>
        <VideoGallery videos={videos} />
      </Section>
      <Section>
        <Container>
          <Subheading>Subscribe</Subheading>
          <p>Sign up to receive news and updates</p>
          <form name="signup" method="POST" data-netlify="true">
            <input type="hidden" name="form-name" value="signup" />
            <label for="email" className={styles.Label}>Email</label>
            <div className={styles.Form}>
              <input type="email" name="email" className={styles.Input} />
              <button type="submit" className={styles.Submit}>Signup</button>
            </div>
          </form>
        </Container>
      </Section>
      <Footer />
    </Page>
  );
}

export const query = graphql`
  query MainQuery {
    allContentfulShow {
      edges {
        node {
          title,
          url,
          date,
          location
        }
      }
    }
    bannerImage: file(relativePath: { eq: "portrait.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    portraitImage: file(relativePath: { eq: "fliss-portrait.jpg" }) {
      childImageSharp {
        fixed(width: 325, height: 475) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    albumImage: file(relativePath: { eq: "album-art.jpg" }) {
      childImageSharp {
        fixed(width: 500, height: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    comfreyOfficialImage: file(relativePath: { eq: "comfrey-official.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    comfreyImage: file(relativePath: { eq: "comfrey-and-calendula.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    boysClubImage: file(relativePath: { eq: "boys-club.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    palominoImage: file(relativePath: { eq: "palomino-princess.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

