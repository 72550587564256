import React from 'react';

import {Icon} from '../Icon';
import {Container} from '../Container';
import {VisuallyHidden} from '../VisuallyHidden';

import styles from './TopBar.module.css';

export function TopBar() {
  return (
    <div className={styles.TopBar}>
      <Container compact>
        <div className={styles.Links}>
          <a className={styles.Link} href="https://www.facebook.com/flissmusicofficial/">
            <Icon name="facebook" />
            <VisuallyHidden>Facebook</VisuallyHidden>
          </a>
          <a className={styles.Link} href="https://www.instagram.com/flissflissflissfliss/">
            <Icon name="instagram" />
            <VisuallyHidden>Instagram</VisuallyHidden>
          </a>
          <a className={styles.Link} href="https://twitter.com/flissofficial">
            <Icon name="twitter" />
            <VisuallyHidden>Twitter</VisuallyHidden>
          </a>
          <a className={styles.Link} href="https://www.youtube.com/channel/UCoXgILWZiZv0odM7WY7x2bA">
            <Icon name="youtube" />
            <VisuallyHidden>YouTube</VisuallyHidden>
          </a>
          <a className={styles.Link} href="https://flissflissflissfliss.bandcamp.com/">
            <Icon name="bandcamp" />
            <VisuallyHidden>Bandcamp</VisuallyHidden>
          </a>
        </div>
      </Container>
    </div>
  )
}
