import React from 'react';
import Img from 'gatsby-image';

import {Container} from '../Container';
import {Subheading} from '../Subheading';

import styles from './Bio.module.css';

export function Bio({image}) {
  return (
    <Container>
      <Subheading>Bio</Subheading>
      <Img className={styles.Image} fixed={image} alt="Portrait of Felicity in pink jacket" />
      <p>Fliss (aka Felicity DeCarle) garnered a name for herself as the lead vocalist, guitarist, and songwriter in the Ottawa-area twangy dream-pop ensemble Sparklesaurus. Now on her own as a solo performer, Fliss tapers her approach and places her sultry, tender vocals and dreamy guitar work at the forefront.</p>
      <p>Her songs delve deep into the emotional cortex, conjuring the weight of pain and lightness of beauty all at once. Whether on stage or through old, dusty speakers, Fliss gently takes the listener’s hand as she guides us on her journey. It is a mesmerizing and comforting experience that is not soon forgotten.</p>
    </Container>
  );
}

