import React from 'react';

import {Subheading} from '../Subheading';
import {Icon} from '../Icon';

import styles from './Footer.module.css';

export function Footer() {
  return (
    <footer className={styles.Footer}>
      <div className={styles.Contact}>
        <div className={styles.ContactTitle}>
          <Subheading>Get in touch</Subheading>
        </div>
        <a className={styles.Link}  href="mailto:flissmusicofficial@gmail.com">flissmusicofficial@gmail.com</a>
      </div>
      <div className={styles.SocialLinks}>
        <a className={styles.Link} href="https://www.facebook.com/flissmusicofficial/">
          <Icon name="facebook" />
          <span>Facebook</span>
        </a>
        <a className={styles.Link} href="https://www.instagram.com/flissflissflissfliss/">
          <Icon name="instagram" />
          <span>Instagram</span>
        </a>
        <a className={styles.Link} href="https://twitter.com/flissofficial">
          <Icon name="twitter" />
          <span>Twitter</span>
        </a>
        <a className={styles.Link} href="https://www.youtube.com/channel/UCoXgILWZiZv0odM7WY7x2bA">
          <Icon name="youtube" />
          <span>YouTube</span>
        </a>
        <a className={styles.Link} href="https://flissflissflissfliss.bandcamp.com/">
          <Icon name="bandcamp" />
          <span>Bandcamp</span>
        </a>
      </div>
    </footer>
  );  
}
