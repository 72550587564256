import React from 'react';

import styles from './Show.module.css';

export function Show({title, url, date, location}) {
  return (
    <div className={styles.Show}>
      <h3 className={styles.Title}><a href={url}>{title}</a></h3>
      <p className={styles.Date}>{date}</p>
      <p className={styles.Location}>{location}</p>
    </div>
  );
}

