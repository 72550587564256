import React, {useState, useRef} from 'react';
import Img from 'gatsby-image';
import classNames from 'classnames';

import {Icon} from '../Icon';
import {debounce} from '../../utilities/debounce';

import styles from './VideoGallery.module.css';


export function VideoGallery({videos}) {
  const galleryRef = useRef(null);
  const [activeEmbed, setActiveEmbed] = useState(0);
  
  const handleContainerScroll = debounce((event) => {
    if (!galleryRef.current) {
      return;
    }

    const embedWidth = galleryRef.current.firstChild.offsetWidth;
    const scrollPosition = galleryRef.current.scrollLeft;
    
    setActiveEmbed(Math.round(scrollPosition / embedWidth));
  }, 10);

  const videosMarkup = videos.map((video) => {
    return (
      <Embed key={video.id} id={video.id} thumbnail={video.thumbnail} title={video.title} />
    );
  });
  
  const indicatorsMarkup = videos.map((video, index) => {
    const indicatorClass = classNames(styles.Indicator, {
      [styles.active]: index === activeEmbed
    });

    return <div key={index} className={indicatorClass} />
  });

  return (
    <div className={styles.VideoGallery}>
      <div
        className={styles.EmbedsContainer}
        ref={galleryRef}
        onScroll={handleContainerScroll}
      >
        {videosMarkup}
      </div>
      <div className={styles.IndicatorsContainer}>
        {indicatorsMarkup}
      </div>
    </div>
  );
}

function Embed({id, thumbnail, title}) {
  const [thumbnailClicked, setThumbnailClicked] = useState(false);
  
  const clickThumbnail = () => setThumbnailClicked(true);
  
  return !thumbnailClicked ? (
    <div className={styles.Embed}>
      <button className={styles.LoadEmbed} onClick={clickThumbnail} type="button">
        <Img fluid={thumbnail} alt={title} objectPosition="center center" />
        <div className={styles.PlayButton}><Icon name="play" large /></div>
      </button>
    </div>
  ) : (
    <div className={styles.Embed}>
      <iframe
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%"
        }}
        src={`https://www.youtube.com/embed/${id}?autoplay=1&mute=1&rel=0`}
        frameBorder="0"
        rel="0"
        controls="0"
        title={title}
        loading="lazy"
        allowFullScreen
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      />
    </div>
  );
} 

