import React from 'react';
import Img from 'gatsby-image';

import {preventOrphans} from '../../utilities/string';

import styles from './Banner.module.css';

export function Banner({title, description, image, altText, action}) {
//  const processedDescription = preventOrphans(description);
//  
//  const actionMarkup = action ? (
//    <a className={styles.Action} href={action.url}>{action.content}</a>
//  ) : null;
//  
//  
//  const overlayMarkup = title || description ? (
//    <div className={styles.Overlay}>
//      <p className={styles.Title}>{title}</p>
//      <p className={styles.Description}>{processedDescription}</p>
//      {actionMarkup}
//    </div>
//  ) : null;

  return (
    <div className={styles.Banner}>
      <Img className={styles.Image} fluid={image} alt={altText} />
    </div>
  );
}

